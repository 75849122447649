<template>
	<transition>
		<div ref="contentsWrap" class="contents-wrap" id="contentsWrap">
			<div class="contents-box">
				<div class="conts-row">
					<div class="conts-box width100per">
						<div class="conts-title">문항구분</div>
						<div>
							<DxSelectBox
								placeholder="선택"
								display-expr="codeNm"
								value-expr="id"
								:width="135"
								:height="30"
								:styling-mode="stylingMode"
								:items="getDivisionType"
								v-model="propContentData.questionDivisionCd"
								@value-changed="e => onChangeDivisionType(e)"
							>
								<DxValidator validation-group="validationGroupName">
									<DxRequiredRule message="문항구분은 필수입니다." />
								</DxValidator>
							</DxSelectBox>
						</div>

						<div class="conts-title">문항구분상세</div>
						<div>
							<DxSelectBox
								placeholder="선택"
								:width="135"
								display-expr="codeNm"
								value-expr="id"
								:styling-mode="stylingMode"
								:items="formData.divisionDetailCdList"
								v-model="propContentData.questionDivisionDetailCd"
							>
								<DxValidator validation-group="validationGroupName">
									<DxRequiredRule message="문항구분상세는 필수입니다." />
								</DxValidator>
							</DxSelectBox>
						</div>

						<div class="conts-title">난이도</div>
						<div>
							<DxSelectBox
								placeholder="난이도 선택"
								display-expr="codeNm"
								value-expr="id"
								:width="135"
								:height="30"
								:styling-mode="stylingMode"
								:items="this.$_getCode('ewm_edu_exam_level')"
								v-model="propContentData.levelCd"
							>
								<DxValidator validation-group="validationGroupName">
									<DxRequiredRule message="난이도는 필수입니다." />
								</DxValidator>
							</DxSelectBox>
						</div>
					</div>
				</div>

				<div class="conts-row">
					<div class="conts-box width100per">
						<div class="conts-title">정답유형</div>
						<div>
							<DxSelectBox
								placeholder="정답유형 선택"
								display-expr="codeNm"
								value-expr="id"
								:width="135"
								:height="30"
								:styling-mode="stylingMode"
								:items="this.$_getCode('ewm_edu_exam_answer_type')"
								v-model="propContentData.answerTypeCd"
								@value-changed="onChangeAnswerType"
							>
								<DxValidator validation-group="validationGroupName">
									<DxRequiredRule message="정답유형은 필수입니다." />
								</DxValidator>
							</DxSelectBox>
						</div>

						<div class="conts-title">사용여부</div>
						<div>
							<DxSelectBox
								placeholder="사용여부 선택"
								display-expr="label"
								value-expr="value"
								:width="135"
								:height="30"
								:styling-mode="stylingMode"
								:items="enums.common.stringUsedFlag.values"
								v-model="propContentData.viewCd"
							>
								<DxValidator validation-group="validationGroupName">
									<DxRequiredRule message="사용여부는 필수입니다." />
								</DxValidator>
							</DxSelectBox>
						</div>

						<div class="conts-title">채첨방식</div>
						<div>
							<DxSelectBox
								placeholder="채점방식 선택"
								display-expr="codeNm"
								value-expr="id"
								:width="135"
								:height="30"
								:styling-mode="stylingMode"
								:items="this.$_getCode('ewm_edu_exam_scoring_type')"
								v-model="propContentData.scoringTypeCd"
							>
								<DxValidator validation-group="validationGroupName">
									<DxRequiredRule message="채점방식은 필수입니다." />
								</DxValidator>
							</DxSelectBox>
						</div>
					</div>
				</div>
				<div class="conts-row">
					<div class="conts-box">
						<div class="conts-title">첨부파일</div>
						<div>
							<DxTextBox
								id="dropzone-external"
								:width="370"
								placeholder="SELECT FILE"
								:styling-mode="stylingMode"
								v-model="formData.fileName"
							>
							</DxTextBox>
							<DxFileUploader
								id="file-uploader"
								upload-mode="useButtons"
								dialog-trigger="#dropzone-external"
								:visible="false"
								@value-changed="onUploadedAttachedFile"
							/>
						</div>

						<div class="conts-title">배점</div>
						<div>
							<DxNumberBox 
								:width="135" 
								:read-only="true" 
								:styling-mode="stylingMode"
								v-model="propContentData.scroe"
							>
								<DxValidator validation-group="validationGroupName">
									<DxRequiredRule message="배점은 필수입니다." />
								</DxValidator>
							</DxNumberBox>
						</div>
					</div>
				</div>
				<div class="conts-row">
					<div class="conts-box">
						<div class="conts-title">문항내용</div>
						<div>
							<DxHtmlEditor
								:width="605"
								:height="100"
								:styling-mode="stylingMode"
								v-model="propContentData.qustionNm"
							>
								<DxToolbar>
									<DxItem name="undo" />
									<DxItem name="redo" />

									<DxItem name="font" />
									<DxItem name="separator" />
									<DxItem name="header" />
									<DxItem name="separator" />
									<DxItem name="bold" />
									<DxItem name="italic" />
									<DxItem name="strike" />
									<DxItem name="underline" />
									<DxItem name="separator" />

									<DxItem name="image" />
									<DxItem name="separator" />
									<DxItem name="insertTable" />
								</DxToolbar>
							</DxHtmlEditor>
						</div>
					</div>
				</div>

				<div class="conts-row">
					<div class="conts-box">
						<div class="conts-title">정답해설</div>
						<div>
							<DxTextBox 
								:width="605" 
								:styling-mode="stylingMode" 
								v-model="propContentData.description" />
						</div>
					</div>
				</div>
			</div>

			<div class="page-bin mar_to20 mar_b10"></div>

			<div class="sub_title_txt clearfix">
				<div class="fr">
					<DxButton
						text="보기추가"
						:visible="true"
						type="button"
						:height="30"
						class="btn_XS white light_filled add2"
						validation-group="validationGroupName"
						@click="addAnswer"
					/>
				</div>
			</div>		

			<div class="page-bin mar_to20 mar_b10"></div>	

			<div>
				<table class="table_list">
					<colgroup>
						<col style="width:15%;" />
						<col style="width:35%;" />
						<col style="width:15%;" />
						<col style="width:5%;" />
					</colgroup>
					<thead class="inner-header">
						<tr>
							<th scope="col">No</th>
							<th scope="col">보기명</th>
							<th scope="col">배점</th>
							<th scope="col"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(answer, index) in propContentData.answers"
								:key="answer.id"
						>
							<td>{{answer.questionNumber}}</td>
							<td>
								<DxTextBox
									placeholder="보기를 입력해주세요."
									class="mar_ri10"
									:styling-mode="stylingMode"
									v-model="answer.description"
								>
								</DxTextBox>
							</td>
							<td>
								<DxNumberBox
									placeholder="점수를 입력해주세요."
									class="mar_ri10"
									:styling-mode="stylingMode"
									v-model="answer.score"
									@key-up="e => onScoreKeyUp(e, index)"
								>
								</DxNumberBox>
							</td>
							<td>
								<DxButton
									text=""
									class="btn-icon trash dx-button dx-button-normal dx-button-mode-contained dx-widget"
									@click="onDeleteAnswerRow(index)"
								/>
							</td>
						</tr>
					</tbody>
				</table>

			</div>
		</div>
	</transition>
</template>

<script>
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import { DxButton } from 'devextreme-vue/button';
import { DxHtmlEditor, DxToolbar, DxItem } from 'devextreme-vue/html-editor';
import { isSuccess } from '@/plugins/common-lib';
import enums from "@/configs/enums";

let vm = this;
export default {
	components: {
		DxTextBox,
		DxSelectBox,
		DxFileUploader,
		DxHtmlEditor,
		DxToolbar,
		DxItem,
		DxButton,
		DxRequiredRule,
		DxValidator,
		DxNumberBox
	},
	props: {
		contentData: Object,
	},
	watch: {
		propContentData: {
			handler(val) {
				let filterData = val;
				if (filterData) {
					this.$emit('input', filterData);
				}
			},
			deep: true,
		}		
	},
	data() {
		return {
			propContentData: this.contentData,
			stylingMode: 'outlined',
			formData: {
				divisionDetailCdList: null,
				fileName: null,
				uploadFile: null,
				scroe: 0,
				questionDivisionDetailCd: null,
			}
		};
	},
	computed: {
    enums() {
      return enums
    },
		getDivisionType() {
			return this.$_getCode('ewm_edu_exam_question_division');
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountData();
	},
	methods: {
		getMaxScore() {
			const arr = vm.propContentData.details.map(object => object.score);
			vm.propContentData.scroe = arr.reduce(function (a, b) {
				return Math.max(a, b);
			}, 0);
		},
		async onDeleteAnswerRow(idx) {
			const questionId = vm.propContentData.questionId;
			const questionNumber = idx + 1;
			const payload = {
				actionname: "EWM_EXAM_QUESTION_DETAIL_DELETE",
				data: {
					data: {
						questionId: questionId,
						questionNumber: questionNumber
					}					
				},				
				loading: false,
			};
			
			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				//const details = res.data.data;
				// row 삭제
				vm.propContentData.details.splice(idx, 1);
				vm.propContentData.details.some((val, idx) => {
					vm.propContentData.details[idx].questionNumber = idx + 1;
				})
				//vm.propContentData.details = details;
				// 합계 score
				vm.getMaxScore();
			}
		},
		onScoreKeyUp(e, index) {
			if(!e.event.target.value){
				return false;
			}
			const current = e.event.target.value;
			vm.propContentData.details[index].score = Number(current);
			vm.propContentData.details.push();

			vm.getMaxScore();
		},
		addAnswer(e) {
			// 정답유형별 row개수 제한
			// -- 1. 서술형(997), 단답형(996) 1개까지 입력가능 
			// -- 2. OX(995) 2개까지 입력가능
			// -- 3. 객관식(994) 5개까지 입력가능

			
			// 선택 필수
			if(!e.validationGroup.validate().isValid) {
				return false;
			}

			const answerTypeCd = vm.propContentData.answerTypeCd;
			const answerSize = vm.propContentData.answers.length;

			// 객관식: 994 5개까지 등록가능
			if(answerTypeCd == 994 && answerSize == 5) {
				this.$_Toast("더 이상 추가 할 수 없습니다.");
				return false;
			}

			// OX: 995 보기 2개까지 등록가능
			if(answerTypeCd == 995 && answerSize == 2) {
				this.$_Toast("더 이상 추가 할 수 없습니다.");
				return false;
			}

			// 단단형: 996 서술형: 997 보기 한개만 등록가능
			if((answerTypeCd == 996 || answerTypeCd == 997) && answerSize == 1) {
				this.$_Toast("더 이상 추가 할 수 없습니다.");
				return false;
			}

			let length = answerSize;
			const addIndex = ++length;
			let questionNumber = {
				questionNumber: addIndex
			}
			if(answerSize == 0) {
				vm.propContentData.details = [];
			}
			vm.propContentData.details.push(questionNumber);
		},

		onChangeAnswerType() {
			vm.propContentData.details = [];
			vm.getMaxScore();	
		},		
		async onUploadedAttachedFile(e) {
			if(e.value.length > 1) {
				this.$_Msg("한 개의 이미지 파일을 올려주세요.");
				return false;
			}
			const file = e.value[0];
			vm.formData.uploadFile = e.value[0];
			vm.formData.fileName = e.value[0].name;

			let formData = new FormData();
			formData.append("file", file);

            let payload = {
                actionname: 'COMMON_ATTACHED_FILE_UPLOAD',
                data: formData,
                loading: false,
            }
            try {
                let res = await this.CALL_API(payload);
                if(res.status === 200) {
					const fileGroupId = res.data?.data[0].fileGroupId;
					vm.propContentData.fileGroupId = fileGroupId;
                }else {
                        this.$_Msg('이미지 업로드 실패 :(');
                }                 
            } catch (error) {
                this.$log.debug(error)
            }
		},
		onChangeDivisionType(e) {
			// 1. 선택된 문항구문 ID
			const selectedDivisionCd = e.value;
			const division_cd_List = this.$_getCode('ewm_edu_exam_question_division').filter(item => selectedDivisionCd == item.id);
			const codeValue = division_cd_List[0].codeValue;
			const divisionDetailCdList = this.$_getCode(codeValue);

			vm.propContentData.questionDivisionDetailCd = null;
			vm.formData.divisionDetailCdList = divisionDetailCdList;
		},
		createdData() {
			vm = this;

			if (vm.propContentData.questionDivisionCd) {
				const selectedDivisionCd = vm.propContentData.questionDivisionCd;
				const division_cd_List = this.$_getCode('ewm_edu_exam_question_division').filter(item => selectedDivisionCd == item.id);
				const codeValue = division_cd_List[0].codeValue;
				vm.formData.divisionDetailCdList = this.$_getCode(codeValue);
			}
		},		
		mountData() {
			
		},
	},
};
</script>

<style scoped>
.contents-title-box {
	height: 40px;
	position: relative;
	border-bottom: 1px solid #ccc;
	line-height: 40px;
}
.contents-title-box .contents-title {
	height: 40px;
	padding-left: 5px;
	display: inline-block;
	font-size: 0.9em;
}

.contents-box {
	width: 100%;
	padding: 20px 10px;
	background-color: #fff;
	border: 1px solid #e0e0e0;
}

.conts-row:not(:last-child) {
	margin-bottom: 20px;
}
.conts-row .conts-box {
	display: inline-block;
}
.conts-row .conts-box > div {
	display: inline-block;
}
.conts-row .conts-box .conts-title {
	width: 100px;
	display: inline-block;
	text-align: center;
}
.conts-row .conts-box .conts-input {
	display: -webkit-inline-box;
}

.conts-row .conts-box .conts-input .limitLength-box {
	margin-left: 5px;
	display: inline-block;
	font-size: 0.9em;
}

.conts-row .conts-box {
	display: inline-block;
}
</style>
<style>
.dx-texteditor-input {
	padding: 0px;
}
.bottom-20 {
	margin-bottom: 20px;
}

.buttonArea {
	margin: 10px 0px;
	float: right;
}

.paddingtop {
	padding-top: 20px;
}

.closes {
	padding-left: 10px;
}
</style>
